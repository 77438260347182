<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div class="container-fluid mt-4">

        <b-alert :show="loading" variant="info">Loading...</b-alert>
        <b-row>
            <b-col>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Label</th>
                            <th>Code</th>
                            <th>Type</th>
                            <th>Expiration</th>
                            <th>Active</th>
                            <th>Last Edit</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="license in licenses" :key="license.id">
                            <td>{{ license.id }}</td>
                            <td>{{ license.label }} <b-badge v-if="license.status == 'Archived'" pill variant="warning">Archived</b-badge></td>
                            <td>{{ license.code }}</td>
                            <td>{{ license.type }}</td>
                            <td>{{ toLocaleDate(license.endDate) }}</td>
                            <td><b-icon-check-circle variant="success" v-if="license.active" /> <b-icon-x-circle variant="danger" v-else /></td>
                            <td>{{ toLocaleDate(license.modificationDatetime) }} (User ID {{ license.modifiedByUser }}) </td>
                            <td class="text-right">
                                <b-row>
                                    <b-button variant="outline-primary"
                                              size="sm"
                                              class="m-1"
                                              @click.prevent="$router.push({ name: 'license', params: { licenseId: license.id } })">
                                        <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
                                    </b-button>
                                    <b-button v-if="isUserAuthorized"
                                              variant="outline-primary"
                                              size="sm"
                                              class="m-1"
                                              @click.prevent="populateLicenseToEdit(license)">
                                        <b-icon-pencil />
                                    </b-button>
                                    <b-button v-if="isIntentoSales"
                                              variant="outline-primary"
                                              size="sm"
                                              class="m-1"
                                              @click.prevent="populateLicenseToMove(license)">
                                        <b-icon-arrow-left-right />
                                    </b-button>
                                    <b-button v-if="isUserAuthorized"
                                              variant="outline-primary"
                                              size="sm"
                                              class="my-1"
                                              @click.prevent="deleteLicense(license.id, license.code)">
                                        <b-icon-trash />
                                    </b-button>
                                </b-row>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-col>

            <b-modal id="modal-add-license"
                     @ok="saveLicense"
                     @cancel="cancelLicenseEdition"
                     ok-title="Save License"
                     :title="(model.id ? 'Edit License ID#' + model.id : 'New License')">
                <form @submit.prevent="saveLicense">
                    <b-form-group label="Label">
                        <b-form-input type="text" v-model="model.label"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Billing Type">
                        <b-form-select v-model="model.type" :options="licenseTypes" />
                    </b-form-group>
                    <b-form-group label="Billing Frequency">
                        <b-form-select v-model="model.billingFrequency" :options="billingTypes" />
                    </b-form-group>
                    <b-form-group label="Start date">
                        <b-form-input type="date" v-model="model.startDate"></b-form-input>
                    </b-form-group>
                    <b-form-group label="End date">
                        <b-form-input type="date" v-model="model.endDate"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Usage limit">
                        <b-form-input type="number" v-model.number="model.usageLimit"></b-form-input>
                    </b-form-group>
                    <b-form-checkbox v-model="model.active" :value="true" :unchecked-value="false" >
                        Active
                    </b-form-checkbox>
                    <b-form-checkbox v-model="model.trackDynamics" :value="true" :unchecked-value="false" >
                        Track Dynamics
                    </b-form-checkbox>
                </form>
                    <div class="form-group">
                        <div v-if="errorMessage" class="alert alert-danger" role="alert">
                            {{ errorMessage }}
                        </div>
                    </div>
            </b-modal>

            <b-modal id="modal-move-license"
                     @ok="moveLicense"
                     ok-title="Move"
                     title="Change Site / Customer"
                     :ok-disabled=!Boolean(modelSiteToMoveSelected)>
                <form @submit.prevent="moveSite">
                    <b-form-group label="Destination customer account">
                        <b-form-select v-model="modelCustomerToMoveSelected" :options="modelCustomerToMoveOptions" @change="populateDestinationSiteOfLicenseToMove"></b-form-select>
                    </b-form-group>
                    <b-form-group label="Destination site">
                        <b-form-select v-model="modelSiteToMoveSelected" :options="modelSiteToMoveOptions"></b-form-select>
                    </b-form-group>
                </form>
                    <div class="form-group">
                        <div v-if="errorMessage" class="alert alert-danger" role="alert">
                            {{ errorMessage }}
                        </div>
                    </div>
            </b-modal>
        </b-row>

        <b-row>
            <b-col>
                <b-button v-if="isUserAuthorized" @click="showNewLicenseForm" size="sm" class="m-1" variant="primary">
                    <b-icon-plus font-scale="1.3"></b-icon-plus>Add License
                </b-button>
            </b-col>
        </b-row>

    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { formatDateToLocaleString, formatDateTimeToLocaleString, formatDateToISOString } from '@/components/utils.js'
    import ApiService from '@/services/api.service';

    export default {
        data() {
            return {
                loading: false,
                licenses: [],
                model: {
                },
                licenseTypes: [
                    { value: 'Time', text: 'By date range' },
                    { value: 'Standalone', text: 'Standalone' },
                    { value: 'Session', text: 'By number of sessions' },
                    { value: 'Stimulation', text: 'By stimulation active time' }
                ],
                billingTypes: [
                    { value: 'Manual', text: 'Manual billing' },
                    { value: 'Weekly', text: 'Weekly' },
                    { value: 'Monthly', text: 'Monthly' },
                    { value: 'Quarterly', text: 'Quarterly' },
                    { value: 'Annually', text: 'Annually' }
                ],
                modelCustomerToMoveOptions : [],
                modelSiteToMoveOptions : [],
                modelCustomerToMoveSelected : null,
                modelSiteToMoveSelected : null,
                errorMessage: ''
            };
        },
        async created() {
            this.refreshLicenses();
        },
        computed: {
            ...mapGetters('auth', ['isIntentoSales', 'isCustomerUser', 'getCustomerIdOfAccountManager'])
        },
        methods: {
            isUserAuthorized() {
                return this.isIntentoSales ||
                    (this.isCustomerUser && this.customerIdOfAccountManager == this.$parent.customerId)
            },
            async refreshLicenses() {
                this.loading = true;
                this.licenses = await ApiService.getSiteLicenses(this.$parent.siteId);
                this.loading = false;
            },
            async showNewLicenseForm() {
                this.model = {};
                this.$bvModal.show('modal-add-license');
                this.errorMessage = '';
            },
            async populateLicenseToEdit(license) {
                this.model = Object.assign({}, license);
                this.model.startDate = this.formatDateForInputField(this.model.startDate);
                this.model.endDate = this.formatDateForInputField(this.model.endDate);
                this.$bvModal.show('modal-add-license');
                this.errorMessage = '';
            },
            async saveLicense(event) {
                event.preventDefault();
                if (this.isLicenseModelValid(this.model)) {
                    if (this.model.id) {
                        this.model.status = this.model.active ? "Active" : "Inactive";
                        await ApiService.updateLicense(this.model.id, this.model).then(
                            () => { 
                                this.model = {}; // reset form
                                // Hide the modal manually
                                this.$nextTick(() => {
                                this.$bvModal.hide("modal-add-license");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
                    } else {
                        this.model.status = this.model.active ? "Active" : "Inactive";
                        this.model.SiteId = Number(this.$parent.siteId);
                        await ApiService.addLicense(this.model).then(
                            () => { 
                                this.model = {}; // reset form
                                // Hide the modal manually
                                this.$nextTick(() => {
                                this.$bvModal.hide("modal-add-license");
                                });
                            },
                            (error) => {
                                this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
                    }
                    await this.refreshLicenses();
                } else {
                    this.errorMessage = "Some of the License fields are not filled. Please be sure to fill at least the type, billingFrequency, startDate and endDate";
                }
            },
            async deleteLicense(id, code) {
                if (confirm(`Are you sure you want to delete license ${code} ?`)) {
                    // if we are editing a license we deleted, remove it from the form
                    if (this.model.id === id) {
                        this.model = {};
                    }
                    await ApiService.deleteLicense(id);
                    await this.refreshLicenses();
                }
            },
            cancelLicenseEdition() {
                this.model = {};
            },
            toLocaleDate(d) {
                return formatDateToLocaleString(d)
            },
            toLocaleDateTime(d) {
                return formatDateTimeToLocaleString(d)
            },
            formatDateForInputField(d) {
                return formatDateToISOString(d)
            },
            async populateLicenseToMove(license) {

                this.model = Object.assign({}, license);
                this.modelLicenseToMove = license.id;

                this.modelCustomerToMoveOptions = [];

                var customerAccounts = await ApiService.getCustomerAccounts();
                for (var customer of customerAccounts) {
                    this.modelCustomerToMoveOptions.push({ value: customer.id, text: customer.company});
                }

                this.$bvModal.show('modal-move-license');

            },
            async populateDestinationSiteOfLicenseToMove() {

                this.modelSiteToMoveOptions = [];

                var sites = await ApiService.getCustomerSites(this.modelCustomerToMoveSelected);
                for (var site of sites) {
                    if (this.model.siteId != site.id) {
                        this.modelSiteToMoveOptions.push({ value: site.id, text: site.name});
                    }
                }

            },
            async moveLicense(event) {
                event.preventDefault();
                this.model.siteId = this.modelSiteToMoveSelected;
                await ApiService.updateLicense(this.model.id, this.model).then(
                            () => { 
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-move-license");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
                this.refreshLicenses();
            },
           isLicenseModelValid(license) {
               return license.type && license.billingFrequency && license.endDate && license.startDate;
           },
        }
    };
</script>
